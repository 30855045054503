import * as React from 'react';
import { selectors } from 'store';
import { toGatsbyProduct } from 'monkeypatch';
import { useGlobal } from 'components/hooks';
import { ModalTop } from 'components/ModalTop';
import { Loading } from 'components/Loading';
import { CartSummary } from 'components/CartSummary';
import { Price } from 'components/Price';
import { ProductListItem } from 'components/ProductListItem';
import { ModalButton, ModalLink } from 'components/ModalLogic';
import emptyImg from 'images/cartEmpty.png';
import retinaEmptyImg from 'images/cartEmpty@2x.png';
import { useCartQuery } from '__generated__/urql';

type WrapperProps = {
  readonly cartEmpty: boolean;
  readonly onClear?: () => void;
};

const Wrapper: React.FC<WrapperProps> = ({ cartEmpty, children, onClear }) => (
  <div className="modal-big flex flex-col" style={{ minHeight: cartEmpty ? 560 : 600 }}>
    <ModalTop title="Корзина" type="big">
      {!cartEmpty && (
        <button
          type="button"
          onClick={onClear}
          className="h-14 px-8 text-gray-64 font-bold leading-5 transition-colors duration-300 hover:text-white"
        >
          Очистить корзину
        </button>
      )}
    </ModalTop>
    <div className="flex flex-col flex-grow modal-content">{children}</div>
  </div>
);

type Props = {
  readonly onClose: () => void;
};

export const CartModal = ({ onClose }: Props) => {
  const [globalState, globalActions] = useGlobal();
  const { addedIds } = globalState.cart;
  const addedIdsLength = addedIds.length;
  const cartEmpty = addedIdsLength === 0;

  const [res] = useCartQuery({
    variables: {
      ids: addedIds,
    },
    pause: cartEmpty,
  });

  if (cartEmpty) {
    return (
      <Wrapper cartEmpty={cartEmpty}>
        <div className="flex flex-col items-center text-center m-auto">
          <img src={emptyImg} srcSet={`${retinaEmptyImg} 2x`} alt="" className="mb-6" />
          <div className="text-black text-3xl font-bold leading-none mb-5">Корзина пуста</div>
          <div className="text-black leading-5 mb-6">Добавляйте продукты в корзину и они появятся здесь</div>
          <div className="flex">
            <ModalLink to="/catalog" className="button button-secondary button-large w-64 mr-8">
              В каталог
            </ModalLink>
            <ModalLink to="/recommendations" className="button button-primary button-large w-64">
              Рекомендуемые
            </ModalLink>
          </div>
        </div>
      </Wrapper>
    );
  }

  if (res.fetching && !res.data) {
    return <Loading transparent />;
  }

  if (res.error) {
    return (
      <Wrapper cartEmpty={cartEmpty}>
        <div className="text-red p-8">Произошла ошибка</div>
      </Wrapper>
    );
  }

  if (!res.data) return null;

  const onClear = () => window.confirm('Очистить корзину?') && globalActions.cart.clear();

  const onRemoveItem = (rowId: number) =>
    window.confirm('Удалить продукт из корзины?') && globalActions.cart.removeItem(rowId);

  const itemSum = selectors.cart.getItemSum(globalState, res.data.products);
  const minItemSum = res.data.deliveryCosts[0].itemSum;
  const canPlaceOrder = minItemSum ? selectors.cart.canPlaceOrder(itemSum, minItemSum) : false;

  return (
    <Wrapper cartEmpty={cartEmpty} onClear={onClear}>
      <>
        <CartSummary
          data={res.data.deliveryCosts}
          canPlaceOrder={canPlaceOrder}
          itemSum={itemSum}
          addedIdsLength={addedIdsLength}
        />
        <div className="flex-grow flex flex-col p-8">
          {!canPlaceOrder && (
            <div className="flex items-center justify-between border border-orange rounded-lg p-4 mb-8">
              <div className="text-orange leading-5">
                <div className="font-bold mb-2">
                  Минимальная стоимость заказа - <Price value={minItemSum} />.
                </div>
                Чтобы отправить заказ, добавьте продукты ещё на <Price value={minItemSum - itemSum} />
              </div>
              <button
                type="button"
                onClick={onClose}
                className="button button-large text-orange bg-orange-light px-4 hover:text-white hover:bg-orange"
              >
                Продолжить покупки
              </button>
            </div>
          )}
          {res.data.products.length > 0 && (
            <div>
              {res.data.products.map((p) => (
                <ProductListItem
                  key={p.rowId}
                  data={toGatsbyProduct(p)}
                  onRemove={() => onRemoveItem(p.rowId)}
                  className="mb-4"
                />
              ))}
            </div>
          )}
          <div className="pt-4 mt-auto">
            {canPlaceOrder ? (
              <ModalButton to="checkout" className="button button-primary button-large w-64">
                Оформить заказ
              </ModalButton>
            ) : (
              <button type="button" onClick={onClose} className="button button-primary button-large px-8">
                Добавить ещё что-то вкусное до минимального заказа
              </button>
            )}
          </div>
        </div>
      </>
    </Wrapper>
  );
};

export const query = /* urqlGraphQL */ `
  query Cart($ids: [Int!]!) {
    products: productsByIds(ids: $ids) {
      rowId
      price
      oldPrice
      ...ProductListItem_data
    }
    deliveryCosts {
      itemSum
      ...CartSummary_data
    }
  }
`;
