import * as React from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';
import { Address } from './Address';
import { useGlobal } from './hooks';
import EditGray from './icons/EditGray';
import EditOrange from './icons/EditOrange';
import PlusGray from './icons/PlusGray';
import PlusOrange from './icons/PlusOrange';

type Props = {
  readonly data: GatsbyTypes.ActiveAddress_DataFragment;
  readonly type: 'Checkout' | 'My';
  readonly toTab?: (t: string, qp: string) => void;
  readonly backTo?: string;
  readonly className?: string;
};

const icons = {
  edit: {
    Gray: EditGray,
    Orange: EditOrange,
  },
  plus: {
    Gray: PlusGray,
    Orange: PlusOrange,
  },
};

export const ActiveAddress = ({ data, type, toTab, backTo, className }: Props) => {
  const [, globalActions] = useGlobal(() => null);

  const backToStr = backTo ? `&backTo=${backTo}` : '';
  const inCheckout = type === 'Checkout';
  const iconSize = inCheckout ? 20 : 16;

  let tab: string;
  let label;
  let extraText;
  let Icon;

  if (data.activeAddress) {
    tab = 'addresses';
    label = <Address data={data.activeAddress} short />;
    extraText = 'Изменить';
    Icon = icons.edit;
  } else {
    tab = 'newAddress';
    label = 'Добавить адрес';
    Icon = icons.plus;
  }

  const onClick = () => {
    if (inCheckout) {
      globalActions.setModal('my');
      window.history.replaceState(null, '', `?modal=my&tab=${tab}${backToStr}`);
    } else {
      toTab!(tab, backToStr);
    }
  };

  return (
    <button
      type="button"
      onClick={onClick}
      className={classnames(
        'group relative flex items-center text-gray-48 leading-6 transition-colors duration-300 hover:text-black',
        className,
      )}
    >
      <span className={classnames(inCheckout && 'mr-auto')}>{label}</span>
      {inCheckout && extraText}
      <Icon.Gray size={iconSize} className="ml-2 transition-opacity duration-300 group-hover:opacity-0" />
      <Icon.Orange
        size={iconSize}
        className="absolute right-0 opacity-0 transition-opacity duration-300 group-hover:opacity-100"
      />
    </button>
  );
};

export const query = graphql`
  fragment ActiveAddress_data on Api_User {
    activeAddress {
      rowId
      ...Address_data
    }
    addresses {
      rowId
    }
  }
`;
