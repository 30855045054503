import * as React from 'react';
import s from './Modal.module.css';

type Props = {
  readonly onClose: () => void;
};

export const Modal: React.FC<Props> = ({ children, onClose }) => {
  const el = React.useRef<HTMLDivElement>(null);

  const handleKeyUp = (e: KeyboardEvent) => {
    e.preventDefault();

    if (e.keyCode === 27) {
      onClose();
    }
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (el.current && !el.current.contains(e.target as Node)) {
      onClose();
    }
  };

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    window.addEventListener('keyup', handleKeyUp, false);
    document.addEventListener('click', handleOutsideClick, true);

    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('keyup', handleKeyUp, false);
      document.removeEventListener('click', handleOutsideClick, true);
    };
  }, [handleKeyUp, handleOutsideClick]);

  return (
    <div className={s.root}>
      <div className={s.content} ref={el}>
        {children}
      </div>
    </div>
  );
};
