import * as React from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';
import { selectors } from 'store';
import { getNameAndWeight } from 'utils';
import { useGlobal } from './hooks';
import { DiscountTag } from './DiscountTag';
import { ModalLink } from './ModalLogic';
import { CartCounter } from './CartCounter';
import TrashGrayIcon from './icons/TrashGray';
import TrashOrangeIcon from './icons/TrashOrange';
import s from './ProductListItem.module.css';

type Props = {
  readonly data: GatsbyTypes.ProductListItem_DataFragment;
  readonly onRemove: () => void;
  readonly className?: string;
};

export const ProductListItem = ({ data, onRemove, className }: Props) => {
  const [quantity] = useGlobal((state) => selectors.cart.getQuantityById(state, data.rowId));

  // чтобы верстка компонента не прыгала при удалении в корзине + чтобы в UX удаление отображалось мгновенно
  if (quantity === 0) return null;

  const [name, weight] = getNameAndWeight(data.name, data.unit, data.weight);

  return (
    <div className={classnames(s.root, className)}>
      <ModalLink
        to={data.pageUrl}
        className="flex w-20 h-20 rounded-lg overflow-hidden flex-shrink-0 mr-8"
        style={{ boxShadow: '0px 8px 16px rgba(3, 10, 26, 0.24)' }}
      >
        <img
          src={data.imageUrl}
          srcSet={`${data.retinaImageUrl} 2x`}
          alt={name}
          data-sizes="auto"
          className="lazyload"
        />
      </ModalLink>
      <div className="w-full flex flex-col overflow-hidden">
        <div className={classnames('flex items-start', weight ? 'mb-2' : 'mb-auto')}>
          <ModalLink to={data.pageUrl} title={name} className={s.title}>
            {name}
          </ModalLink>
          <DiscountTag price={data.price} oldPrice={data.oldPrice} className="mr-10" />
          <button type="button" onClick={onRemove} className={s.removeButton}>
            <span className="mr-2">Удалить</span>
            <TrashGrayIcon size={16} className={classnames(s.removeButtonIcon, s.removeButtonIconGray)} />
            <TrashOrangeIcon size={16} className={classnames(s.removeButtonIcon, s.removeButtonIconOrange)} />
          </button>
        </div>
        {weight && <div className="text-gray-48 text-lg leading-5">{weight}</div>}
        <CartCounter data={data} type="ProductListItem" />
      </div>
    </div>
  );
};

export const query = graphql`
  fragment ProductListItem_data on Api_Product {
    name
    imageUrl: mainImageUrl(size: 285)
    retinaImageUrl: mainImageUrl(size: 570)
    unit
    weight
    pageUrl
    price
    oldPrice
    ...CartCounter_data
  }
`;
