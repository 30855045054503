import { createElement, HTMLAttributes } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getProps } from './getProps';

interface Props extends HTMLAttributes<HTMLDivElement> {
  readonly name: string;
  readonly size?: number;
}

export const Icon = ({ name, size, ...props }: Props) => {
  const data = useStaticQuery<GatsbyTypes.IconsQuery>(graphql`
    query IconsQuery {
      allFile {
        nodes {
          name
          publicURL
        }
      }
    }
  `);

  const widthHeight = size ? { width: `${size}px`, height: `${size}px` } : {};

  const file = data.allFile.nodes.find((n) => n.name === name);

  if (file) {
    const style = { backgroundImage: `url(${file.publicURL})`, ...widthHeight };

    return createElement('i', getProps('', { ...props, style }));
  }

  return null;
};
