import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Actions } from 'store';
import { validateEmailOrPhone, normalizePhone, captureException } from 'utils';
import img from 'images/auth.png';
import retinaImg from 'images/auth@2x.png';
import { useAuthViewerQuery, AuthViewerQuery, SignInInput, useAuthSignInMutation } from '__generated__/urql';
import { useGlobal } from './hooks';
import { Loading } from './Loading';
import { TextField } from './TextField';
import { ModalTop } from './ModalTop';

type AuthProps = {
  readonly token: string;
  readonly updateToken: Actions['updateToken'];
  readonly setModal: Actions['setModal'];
};

const Auth = ({ token, updateToken, setModal }: AuthProps) => {
  const defaultValues: SignInInput = {
    login: '',
    password: '',
  };

  if (process.env.NODE_ENV !== 'production') {
    defaultValues.login = '79999999901';
    defaultValues.password = 'testtest1';
  }

  const [mutationState, mutate] = useAuthSignInMutation();
  const { register, handleSubmit, errors, getValues } = useForm<SignInInput>({ defaultValues });
  const [errorMessage, setErrorMessage] = React.useState('');

  const onSubmit = handleSubmit(async (input) => {
    try {
      const login = input.login.includes('@') ? input.login : normalizePhone(input.login);
      const res = await mutate({ token, input: { ...input, login } });

      if (res.error) {
        throw res.error;
      }

      if (res.data) {
        if (res.data.result.__typename === 'SignPayload') {
          setErrorMessage('');
          updateToken(res.data.result.token);
        } else if (res.data.result.__typename === 'ErrorPayload') {
          setErrorMessage(res.data.result.message);
        } else {
          throw new Error('unexpected payload type');
        }
      }
    } catch (error) {
      setErrorMessage('Произошла ошибка');
      captureException(error);
    }
  });

  const toForgotPassword = () => {
    const { login } = getValues();
    const phone = login && !login.includes('@') ? normalizePhone(login) : '';
    const qs = phone ? `&phone=${phone}` : '';
    setModal('forgot-password');
    window.history.replaceState(null, '', `?modal=forgot-password${qs}`);
  };

  return (
    <div className="modal-small text-black">
      <ModalTop title="Личный кабинет" type="small" />
      <div className="modal-content py-8">
        <img src={img} srcSet={`${retinaImg} 2x`} alt="" className="ml-8 mb-6" />
        <div className="text-3xl font-bold leading-9 px-8">Войти в личный кабинет</div>
        <div className="border-b border-gray-90 my-8" />
        <form onSubmit={onSubmit} className="px-8">
          <TextField
            name="login"
            register={register({ validate: validateEmailOrPhone })}
            label="Введите email или номер телефона *"
            error={errors.login}
            className="mb-4"
          />
          <div className="relative mb-6">
            <TextField
              name="password"
              register={register({ required: 'Введите пароль' })}
              label="Введите пароль *"
              error={errors.password}
              type="password"
            />
            <button type="button" onClick={toForgotPassword} className="absolute top-0 right-0 text-orange leading-5">
              Забыли пароль?
            </button>
          </div>
          {errorMessage && <div className="text-red mb-6">{errorMessage}</div>}
          <button type="submit" className="button button-primary button-large w-56">
            Войти
          </button>
        </form>
      </div>
      {mutationState.fetching && <Loading inModal />}
    </div>
  );
};

export const useViewer = (): [AuthViewerQuery['viewer'] | null, null] | [null, React.ReactNode] => {
  const [token, globalActions] = useGlobal((s) => s.token);

  const [result] = useAuthViewerQuery({
    variables: { token: token || 'guest' },
    pause: !token,
  });

  if (!token) {
    return [null, null];
  }

  if (result.fetching) {
    return [null, <Loading transparent />];
  }

  if (result.error) {
    return [null, <div>Error: {result.error}</div>];
  }

  if (result.data && !result.data.viewer.user) {
    return [null, <Auth token={token} updateToken={globalActions.updateToken} setModal={globalActions.setModal} />];
  }

  if (result.data) {
    return [result.data?.viewer, null];
  }

  return [null, null];
};

export const viewerQuery = /* urqlGraphQL */ `
  query AuthViewer($token: String!) {
    viewer(token: $token) {
      user {
        ...My_data
      }
    }
  }
`;

export const mutation = /* urqlGraphQL */ `
  mutation AuthSignIn($token: String!, $input: SignInInput!) {
    result: signIn(token: $token, input: $input) {
      __typename

      ... on SignPayload {
        token
      }

      ... on ErrorPayload {
        message
      }
    }
  }
`;
