import * as React from 'react';
import { graphql } from 'gatsby';
import { formatDay, formatNumberTime, groupBy } from 'utils';
import ChevronDown from './icons/ChevronDown';

type Props = {
  readonly intervals: GatsbyTypes.DeliveryTimePicker_IntervalFragment[];
  readonly value?: string;
  readonly onChange?: (v: string) => void;
};

export const DeliveryTimePicker = ({ intervals, value, onChange }: Props) => {
  const dayGroups = groupBy(intervals, (v) => formatDay(v.value));
  const selectedDay = formatDay(value || new Date().toISOString());
  const dayIntervals = intervals.filter((i) => formatDay(i.value as string) === selectedDay);

  const onChangeDaysSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const first = intervals.find((i) => formatDay(i.value as string) === e.target.value)!;
    onChange!(first.value);
  };

  const onChangeTimeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => onChange!(e.target.value);

  return (
    <>
      <div className="relative flex items-center mb-4">
        <select
          value={selectedDay}
          onChange={onChangeDaysSelect}
          className="w-full h-12 text-black bg-gray-95 text-base rounded-lg appearance-none outline-none px-4"
        >
          {Object.keys(dayGroups).map((k) => (
            <option key={k} value={k}>
              {k}
            </option>
          ))}
        </select>
        <ChevronDown className="absolute" style={{ right: 12 }} />
      </div>
      <div className="relative flex items-center">
        <select
          value={value}
          onChange={onChangeTimeSelect}
          className="w-full h-12 text-black bg-gray-95 text-base rounded-lg appearance-none outline-none px-4"
        >
          {dayIntervals.map((d) => (
            <option key={d.id} value={d.value}>
              {formatNumberTime(d.from)} - {formatNumberTime(d.to)}
            </option>
          ))}
        </select>
        <ChevronDown className="absolute" style={{ right: 12 }} />
      </div>
    </>
  );
};

export const query = graphql`
  fragment DeliveryTimePicker_interval on Api_DeliveryInterval {
    id
    from
    to
    value
  }
`;
