import * as React from 'react';
import { formatDay } from 'utils';
import { DepositTransaction_DataFragment } from '__generated__/urql';
import { Price } from './Price';

type Props = {
  readonly data: DepositTransaction_DataFragment;
};

export const DepositTransaction = ({ data: transaction }: Props) => {
  const getLabel = (t: DepositTransaction_DataFragment) => {
    if (t.orderId) {
      return `Заказ №${t.orderId}`;
    }

    if (parseInt(t.value, 10) > 0) {
      return 'Пополнение';
    }

    return 'Заказ';
  };

  return (
    <div className="text-black border-t border-gray-90 leading-6 py-4">
      <div className="flex justify-between mb-1">
        <div>{getLabel(transaction)}</div>
        {transaction.createdAt && <div>{formatDay(transaction.createdAt as string)}</div>}
      </div>
      <div className="flex justify-between">
        <Price value={parseFloat(transaction.value)} className="text-orange font-bold" />
        <div>
          Остаток: <Price value={parseFloat(transaction.stepValue)} className="font-bold" />
        </div>
      </div>
    </div>
  );
};

export const fragment = /* urqlGraphQL */ `
  fragment DepositTransaction_data on DepositTransaction {
    orderId
    value
    stepValue
    createdAt
  }
`;
