import * as React from 'react';
import classnames from 'classnames';
import WhiteIcon from './icons/SpinnerWhite';
import BlackIcon from './icons/SpinnerBlack';

type Props = {
  readonly transparent?: boolean;
  readonly inModal?: boolean;
};

export const Loading = ({ transparent, inModal }: Props) => {
  let bgOpacity = '0.8';

  if (transparent) {
    bgOpacity = '0';
  }

  if (inModal) {
    bgOpacity = '0.1';
  }

  return (
    <div
      className={classnames('flex items-center justify-center fixed inset-0', inModal ? 'z-50' : 'z-30')}
      style={{ backgroundColor: `rgba(23,24,26,${bgOpacity})` }}
    >
      {inModal ? <BlackIcon size={70} /> : <WhiteIcon size={70} />}
    </div>
  );
};
