import * as React from 'react';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import img from 'images/successOrder.png';
import retinaImg from 'images/successOrder@2x.png';
import { getQueryParams, captureException } from 'utils';
import { TextField } from 'components/TextField';
import { useGlobal } from 'components/hooks';
import { Loading } from 'components/Loading';
import { ModalTop } from 'components/ModalTop';
import { ModalButton } from 'components/ModalLogic';
import { AttachOrderAndSignInInput, useCheckoutFinishModalMutation } from '__generated__/urql';

type Props = {
  readonly onClose: () => void;
};

const buttonCn = 'button button-primary button-large w-56';

export const CheckoutFinishModal = ({ onClose }: Props) => {
  const [mutationState, mutate] = useCheckoutFinishModalMutation();
  const { register, handleSubmit, errors } = useForm<AttachOrderAndSignInInput>();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [, globalActions] = useGlobal(() => null);

  const { authCodeSended, id: rawId, onlinePaymentUrl } = getQueryParams();

  if (typeof rawId !== 'string') {
    return null;
  }

  const orderId = parseInt(rawId, 10);

  // check is NaN
  if (!orderId) {
    return null;
  }

  const onSubmit = handleSubmit(async (values) => {
    try {
      const res = await mutate({
        input: { ...values, orderId },
      });

      if (res.error) {
        throw res.error;
      }

      if (res.data) {
        if (res.data.attachOrderAndSignIn.__typename === 'SignPayload') {
          globalActions.updateToken(res.data.attachOrderAndSignIn.token);
        } else if (res.data && res.data.attachOrderAndSignIn.__typename === 'ErrorPayload') {
          setErrorMessage(res.data.attachOrderAndSignIn.message);
        } else {
          throw new Error('unexpected payload type');
        }
      }
    } catch (error) {
      setErrorMessage('Произошла ошибка');
      captureException(error);
    }
  });

  const signedWithCode = mutationState.data?.attachOrderAndSignIn.__typename === 'SignPayload';

  const buttons =
    onlinePaymentUrl !== 'null' ? (
      <a href={onlinePaymentUrl as string} rel="noopener noreferrer" target="blank" className={buttonCn}>
        Перейти к оплате
      </a>
    ) : (
      <button type="button" onClick={onClose} className={buttonCn}>
        Отлично
      </button>
    );

  return (
    <div className="modal-small text-black">
      <ModalTop title="Оформление заказа" type="small" />
      <div className="modal-content py-8">
        <div className="px-8">
          <img src={img} srcSet={`${retinaImg} 2x`} alt="" className="mb-6" />
          <div className="text-3xl font-bold leading-9 mb-4">Ваш заказ оформлен</div>
          <div className="text-lg leading-6 mb-4">
            Номер заказа: <b className="text-orange">{orderId}</b>
          </div>
          <div className="leading-5 mb-6">
            Окончательная стоимость может отличаться на 10% и определиться точно после взвешивания всех позиций
          </div>
        </div>
        {authCodeSended === 'true' && !signedWithCode && (
          <form onSubmit={onSubmit} className="border-t border-gray-90 pt-8 px-8 mt-8">
            <div className="text-lg font-bold leading-6 mb-2">Введите код из смс</div>
            <div className="leading-5 mb-6">Мы отправили на ваш номер телефона код для входа в личный кабинет</div>
            <TextField
              name="code"
              register={register({ required: 'Введите код' })}
              error={errors.code}
              className="mb-6"
            />
            {errorMessage && <div className="text-red text-center mb-4">{errorMessage}</div>}
            <button type="submit" className={buttonCn}>
              Войти
            </button>
          </form>
        )}
        <div className={cn('flex px-8', signedWithCode && 'text-sm')}>
          {(authCodeSended === 'false' || signedWithCode) && buttons}
          {signedWithCode && (
            <ModalButton to="my" className={cn(buttonCn, 'ml-4')}>
              В личный кабинет
            </ModalButton>
          )}
        </div>
        {mutationState.fetching && <Loading inModal />}
      </div>
    </div>
  );
};

export const mutation = /* urqlGraphQL */ `
  mutation CheckoutFinishModal($input: AttachOrderAndSignInInput!) {
    attachOrderAndSignIn(input: $input) {
      __typename 

      ... on SignPayload {
        token
        viewer {
          token
          user {
            depositBalance
            mainPhone
            ...ActiveAddress_data
            lastOrder {
              ecoPack
              withoutOverweight
              asap
              paymentMethod
            }
          }
        }
      }

      ... on ErrorPayload {
        message
      }
    }
  }
`;
