import * as React from 'react';
import classnames from 'classnames';
import { Icon } from './icons';
import { Tooltip } from './Tooltip';
import s from './CheckboxInput.module.css';

type Props = {
  readonly name: string;
  readonly register: (ref: HTMLInputElement) => void;
  readonly label: string;
  readonly description: string;
  readonly className?: string;
};

export const CheckboxInput = ({ name, register, label, description, className }: Props) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label className={classnames('flex items-center cursor-pointer select-none', className)}>
    <input type="checkbox" name={name} ref={register} className={s.checkboxInput} />
    <div className={s.customCheckbox}>
      <Icon name="checkWhite" size={16} className={s.customCheckboxIcon} />
    </div>
    <div className="text-black font-bold leading-none mr-2">{label}</div>
    <Tooltip label={description} />
  </label>
);
