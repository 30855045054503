import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/browser';
import { MyProfile_DataFragment, ChangeProfileInput, useMyProfileMutation } from '__generated__/urql';
import { TextField } from './TextField';
import { useGlobal } from './hooks';
import { Loading } from './Loading';

type Props = {
  readonly data: MyProfile_DataFragment;
};

export const MyProfile = ({ data }: Props) => {
  const [token] = useGlobal((s) => s.token);

  if (!token) {
    return null;
  }

  const [mutationState, mutate] = useMyProfileMutation();
  const { register, handleSubmit, errors } = useForm<ChangeProfileInput>({
    defaultValues: {
      ...data,
      phone: data.mainPhone,
    },
  });
  const [errorMessage, setErrorMessage] = React.useState('');

  const onSubmit = handleSubmit(async (input) => {
    try {
      const res = await mutate({ token, input });

      setErrorMessage('');

      if (res.data && res.data.result.__typename === 'ErrorPayload') {
        setErrorMessage(res.data.result.message);
        Sentry.captureException(res.data.result.message);
      }
    } catch (e) {
      setErrorMessage('Произошла внутренняя ошибка');
      Sentry.captureException(e);
    }
  });

  return (
    <div className="flex flex-col flex-grow">
      <div className="text-black text-3xl font-bold leading-none text-center mb-8">Изменить профиль</div>
      <form onSubmit={onSubmit} className="flex flex-col flex-grow">
        <div className="flex mb-4">
          <TextField
            name="firstName"
            label="Имя"
            register={register({ required: 'Введите имя' })}
            error={errors.firstName}
            className="w-1/2 mr-8"
          />
          <TextField name="lastName" label="Фамилия" register={register} className="w-1/2" />
        </div>
        <div className="flex mb-4">
          <TextField name="email" label="Email" register={register} className="w-1/2 mr-8" />
          <TextField name="phone" label="Телефон" register={register} className="w-1/2" />
        </div>
        {errorMessage && <div className="text-red text-center">{errorMessage}</div>}
        <button type="submit" className="button button-primary button-large w-64 mx-auto mt-auto">
          Сохранить изменения
        </button>
      </form>
      {mutationState.fetching && <Loading inModal />}
    </div>
  );
};

export const fragment = /* urqlGraphQL */ `
  fragment MyProfile_data on User {
    firstName
    lastName
    mainPhone
    email
  }
`;

export const mutation = /* urqlGraphQL */ `
  mutation MyProfile($token: String!, $input: ChangeProfileInput!) {
    result: changeProfile(token: $token, input: $input) {
      __typename

      ... on User {
        ...MyProfile_data
      }

      ... on ErrorPayload {
        message
      }
    }
  }
`;
