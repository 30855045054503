/* eslint-disable @typescript-eslint/indent */
import { ProductStatus } from './__generated__/urql';

// костыль, чтобы насильно приводить Product -> GatsbyTypes.Api_ProductStatus
export const toGatsbyProduct = <S extends { status: ProductStatus }>({
  status,
  ...otherData
}: S) => ({
  ...otherData,
  status: (status as unknown) as GatsbyTypes.Api_ProductStatus,
});
