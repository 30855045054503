import * as React from 'react';
import { MyAddressItem_DataFragment } from '__generated__/urql';
import { Address } from './Address';
import CheckIcon from './icons/CheckWhite';
import TrashIcon from './icons/TrashGray';
import s from './MyAddressItem.module.css';

type Props = {
  readonly data: MyAddressItem_DataFragment;
  readonly onChange: () => void;
  readonly onDelete: () => void;
  readonly active: boolean;
};

export const MyAddressItem = ({ data, onChange, onDelete, active }: Props) => (
  <div className={s.root}>
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label className="flex items-center cursor-pointer">
      <input type="checkbox" checked={active} onChange={onChange} className={s.checkboxField} />
      <span className={s.customCheckbox}>
        <CheckIcon size={16} className={s.customCheckboxIcon} />
      </span>
      <span className="text-black leading-6">
        <Address data={data} />
      </span>
    </label>
    <button type="button" onClick={onDelete}>
      <TrashIcon size={20} />
    </button>
  </div>
);

export const fragment = /* urqlGraphQL */ `
  fragment MyAddressItem_data on Address {
    ...Address_data
  }
`;
