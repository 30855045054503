import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Actions } from 'store';
import { validatePhone, getQueryParams, normalizePhone, captureException, formatPhone } from 'utils';
import { useGlobal } from 'components/hooks';
import { TextField } from 'components/TextField';
import { Loading } from 'components/Loading';
import { ModalTop } from 'components/ModalTop';
import img from 'images/forgotPassword.png';
import retinaImg from 'images/forgotPassword@2x.png';
import {
  RequestSignInCodeInput,
  useForgotPasswordRequestSignInCodeMutation,
  useForgotPasswordSignInByCodeMutation,
  SignInByCodeInput,
} from '__generated__/urql';

type StepOneProps = {
  readonly token: string;
  readonly onSuccess: (phone: string) => void;
};

const StepOne = ({ token, onSuccess }: StepOneProps) => {
  const qp = getQueryParams();
  const phoneQP = typeof qp.phone === 'string' ? qp.phone : '';

  const { register, handleSubmit, errors } = useForm<RequestSignInCodeInput>({
    defaultValues: {
      phone: phoneQP,
    },
  });
  const [mutationState, mutate] = useForgotPasswordRequestSignInCodeMutation();
  const [errorMessage, setErrorMessage] = React.useState('');

  const onSubmit = handleSubmit(async (input) => {
    try {
      const phone = normalizePhone(input.phone);
      const res = await mutate({ token, input: { phone } });

      if (res.error) {
        throw res.error;
      }

      if (res.data) {
        if (res.data.result?.__typename === 'ErrorPayload') {
          setErrorMessage(res.data.result.message);
        } else {
          setErrorMessage('');
          onSuccess(phone);
        }
      }
    } catch (error) {
      setErrorMessage('Произошла ошибка');
      captureException(error);
    }
  });

  return (
    <>
      <div className="leading-5 px-8">
        Введите номер телефона, который вы использовали при оформлении заказов, и мы вышлем вам временный код для входа.
      </div>
      <div className="border-b border-gray-90 my-8" />
      <form onSubmit={onSubmit} className="px-8">
        <TextField
          name="phone"
          register={register({ validate: validatePhone })}
          label="Введите номер телефона *"
          error={errors.phone}
          className="mb-6"
        />
        {errorMessage && <div className="text-red mb-6">{errorMessage}</div>}
        <button type="submit" className="button button-primary button-large w-56">
          Отправить код
        </button>
      </form>
      {mutationState.fetching && <Loading inModal />}
    </>
  );
};

type StepTwoProps = {
  readonly phone: string;
  readonly token: string;
  readonly updateToken: Actions['updateToken'];
  readonly setModal: Actions['setModal'];
};

const StepTwo = ({ phone, token, updateToken, setModal }: StepTwoProps) => {
  const { register, handleSubmit, errors } = useForm<SignInByCodeInput>();
  const [mutationState, mutate] = useForgotPasswordSignInByCodeMutation();
  const [errorMessage, setErrorMessage] = React.useState('');

  const onSubmit = handleSubmit(async (input) => {
    try {
      const res = await mutate({ token, input: { ...input, phone } });

      if (res.error) {
        throw res.error;
      }

      if (res.data) {
        if (res.data.result.__typename === 'SignPayload') {
          setErrorMessage('');
          updateToken(res.data.result.token);
          setModal('my');
          window.history.replaceState(null, '', '?modal=my');
        } else if (res.data.result.__typename === 'ErrorPayload') {
          setErrorMessage(res.data.result.message);
        } else {
          throw new Error('unexpected payload type');
        }
      }
    } catch (error) {
      setErrorMessage('Произошла ошибка');
      captureException(error);
    }
  });

  return (
    <>
      <div className="font-bold leading-5 mb-2 px-8">
        Мы отправили вам временный код на номер: <br />
        {formatPhone(phone)}.
      </div>
      <div className="leading-5 px-8">
        В целях безопасности мы НЕ храним ваш пароль. Поэтому после восстановления доступа, рекомендуем сменить пароль
        на новый.
      </div>
      <div className="border-b border-gray-90 my-8" />
      <form onSubmit={onSubmit} className="px-8">
        <TextField
          name="code"
          register={register({ required: 'Введите код из смс' })}
          label="Введите код из смс *"
          error={errors.code}
          className="mb-6"
        />
        {errorMessage && <div className="text-red mb-6">{errorMessage}</div>}
        <button type="submit" className="button button-primary button-large w-56">
          Войти
        </button>
      </form>
      {mutationState.fetching && <Loading inModal />}
    </>
  );
};

type Props = {
  readonly onClose: () => void;
};

export const ForgotPasswordModal = ({ onClose }: Props) => {
  const [globalState, globalActions] = useGlobal();
  const [phone, setPhone] = React.useState('');

  React.useEffect(() => {
    if (globalState.user || !globalState.token) {
      onClose();
    }
  }, [globalState]);

  const onSuccess = (p: string) => setPhone(p);

  if (!globalState.token) return null;

  return (
    <div className="modal-small text-black">
      <ModalTop title="Забыли пароль?" type="small" />
      <div className="modal-content py-8">
        <img src={img} srcSet={`${retinaImg} 2x`} alt="" className="ml-8 mb-6" />
        <div className="text-3xl font-bold leading-9 px-8 mb-4">Восстановление пароля</div>
        {phone ? (
          <StepTwo
            phone={phone}
            token={globalState.token}
            updateToken={globalActions.updateToken}
            setModal={globalActions.setModal}
          />
        ) : (
          <StepOne token={globalState.token} onSuccess={onSuccess} />
        )}
      </div>
    </div>
  );
};

export const requestSignInCodeMutation = /* urqlGraphQL */ `
  mutation ForgotPasswordRequestSignInCode($token: String!, $input: RequestSignInCodeInput!) {
    result: requestSignInCode(token: $token, input: $input) {
      __typename

      ... on ErrorPayload {
        message
      }
    }
  }
`;

export const signInByCodeMutation = /* urqlGraphQL */ `
  mutation ForgotPasswordSignInByCode($token: String!, $input: SignInByCodeInput!) {
    result: signInByCode(token: $token, input: $input) {
      __typename

      ... on SignPayload {
        token
      }

      ... on ErrorPayload {
        message
      }
    }
  }
`;
