import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { captureException } from '@sentry/browser';
import { selectors } from 'store';
import { useGlobal } from 'components/hooks';
import { CartSummary } from 'components/CartSummary';
import { CheckboxInput } from 'components/CheckboxInput';
import { TextField } from 'components/TextField';
import { PaymentMethodInput } from 'components/PaymentMethodInput';
import { ActiveAddress } from 'components/ActiveAddress';
import { DeliveryTimePicker } from 'components/DeliveryTimePicker';
import { Loading } from 'components/Loading';
import { ModalTop } from 'components/ModalTop';
import { normalizePhone, validatePhone } from 'utils';
import { getItem, setItem, removeItem } from 'storage';
import * as analytics from 'analytics';
import { useCheckoutQueryQuery, PlaceOrderInput, PaymentMethod, useCheckoutMutationMutation } from '__generated__/urql';
import s from './Checkout.module.css';

const Wrapper: React.FC = ({ children }) => (
  <div className="flex flex-col modal-big" style={{ minHeight: 560 }}>
    <ModalTop title="Оформление заказа" type="big" />
    <div className="modal-content flex-grow">{children}</div>
  </div>
);

type Props = {
  readonly onClose: () => void;
};

export const CheckoutModal = ({ onClose }: Props) => {
  const [globalState, globalActions] = useGlobal();
  const { token } = globalState;
  const { addedIds } = globalState.cart;

  React.useEffect(() => {
    if (!token || addedIds.length === 0) {
      onClose(); // закрываем модалку с оверлеем
    }
  }, [globalState]);

  if (!token) {
    // чтобы ts не ругался, так то закрываем модалку выше
    return null;
  }

  const [res] = useCheckoutQueryQuery({
    variables: {
      ids: addedIds,
      token,
    },
  });

  const [mutationState, mutate] = useCheckoutMutationMutation();
  const { handleSubmit, setValue, watch, register, control, errors } = useForm<PlaceOrderInput>();
  const [errorMessage, setErrorMessage] = React.useState('');

  const watchAllFields = watch();

  React.useEffect(() => {
    if (Object.keys(watchAllFields).length > 0) {
      setItem('checkout', {
        date: Date.now(),
        data: watchAllFields,
      });
    }
  }, [watchAllFields]);

  React.useEffect(() => {
    if (res.data) {
      const { viewer } = res.data;
      let defaultValues: Partial<PlaceOrderInput> = {
        name: '',
        phone: '',
        address: '',
        comment: '',
        ecoPack: viewer.user?.lastOrder?.ecoPack || false,
        withoutOverweight: viewer.user?.lastOrder?.withoutOverweight || false,
        asap: viewer.user?.lastOrder?.asap || false,
        paymentMethod: viewer.user?.lastOrder?.paymentMethod || PaymentMethod.BY_CASH,
        deliveryAt: res.data.deliveryIntervals[0].value as string,
      };

      const depositBalance = viewer?.user?.depositBalance || 0;
      if (depositBalance > 0) {
        defaultValues.paymentMethod = PaymentMethod.DEPOSIT;
      }

      const checkout = getItem('checkout');
      if (checkout?.date && checkout?.data && checkout?.date + 15 * 60 * 1000 > Date.now()) {
        defaultValues = { ...defaultValues, ...checkout.data };
      }

      Object.keys(defaultValues).forEach((key: keyof PlaceOrderInput) => setValue(key, defaultValues[key]));
    }
  }, [res.data]);

  if (res.fetching || !res.data) {
    return <Loading transparent />;
  }

  if (res.error) {
    return (
      <Wrapper>
        <div className="text-red p-8">Произошла ошибка</div>
      </Wrapper>
    );
  }

  const itemSum = selectors.cart.getItemSum(globalState, res.data.products);
  const minItemSum = res.data.deliveryCosts[0].itemSum;
  const canPlaceOrder = selectors.cart.canPlaceOrder(itemSum, minItemSum);

  const { viewer } = res.data;

  const onSubmit = handleSubmit(async (values) => {
    try {
      const items = Object.keys(globalState.cart.quantityById).map((id) => {
        const productId = parseInt(id, 10);

        return {
          productId,
          quantity: globalState.cart.quantityById[productId],
        };
      });

      const input = {
        ...values,
        items,
        addressId: viewer.user?.activeAddress?.rowId as number,
        origin: 'desktop',
      };

      if (input.phone) {
        input.phone = normalizePhone(input.phone).replace(/^\+/, '');
      }

      const response = await mutate({
        token,
        input,
        yandex: values.paymentMethod === 'ONLINE',
        clearCart: false,
      });

      if (response.data && response.data.placeOrder.__typename === 'PlaceOrderPayload') {
        globalActions.cart.clear();
        removeItem('checkout');
        const id = response.data.placeOrder.orderId;
        const onlinePaymentUrl =
          input.paymentMethod === PaymentMethod.ONLINE ? response.data.placeOrder.onlinePaymentUrl : null;
        analytics.purchase({
          orderId: response.data.placeOrder.orderId,
          totalSum: response.data.placeOrder.totalSum,
          items: items.map((i) => {
            const product = res.data && res.data.products.find((p) => p.rowId === i.productId);

            return {
              productId: i.productId,
              quantity: i.quantity,
              price: product?.price || 100,
            };
          }),
        });

        const authCodeSended = response.data.placeOrder.authCodeSended || false;

        globalActions.setModal('checkoutFinish');
        window.history.replaceState(
          null,
          '',
          `?modal=checkoutFinish&id=${id}&onlinePaymentUrl=${onlinePaymentUrl}&authCodeSended=${authCodeSended}`,
        );
      } else if (response.data && response.data.placeOrder.__typename === 'ErrorPayload') {
        setErrorMessage(response.data.placeOrder.message);
      } else {
        throw new Error('unexpected typename');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      captureException(error);
      setErrorMessage('Внутренняя ошибка');
    }
  });

  return (
    <Wrapper>
      <>
        {canPlaceOrder && (
          <CartSummary
            data={res.data.deliveryCosts}
            canPlaceOrder={canPlaceOrder}
            itemSum={itemSum}
            addedIdsLength={addedIds.length}
          />
        )}
        <form onSubmit={onSubmit} className="p-8">
          {viewer.user && !viewer.user.mainPhone && (
            <div className={s.block}>
              <div className={s.blockTitle}>Контактные данные:</div>
              <TextField
                name="phone"
                register={register({ validate: validatePhone })}
                error={errors.phone}
                placeholder="Введите номер телефона"
              />
            </div>
          )}
          {viewer.user && (
            <div className={s.block}>
              <div className={s.blockTitle}>Адрес доставки:</div>
              <ActiveAddress data={viewer.user} backTo="checkout" type="Checkout" className="w-full" />
            </div>
          )}
          {!viewer.user && (
            <div className={s.block}>
              <div className={s.blockTitle}>Контактные данные:</div>
              <div className="grid grid-cols-2 gap-4">
                <TextField
                  name="name"
                  register={register({ required: 'Введите ваше имя' })}
                  error={errors.name}
                  placeholder="Как к вам обращаться?"
                  className="col-span-2"
                />
                <TextField
                  name="phone"
                  register={register({ validate: validatePhone })}
                  error={errors.phone}
                  placeholder="Введите номер телефона"
                />
                <TextField
                  name="address"
                  register={register({ required: 'Введите ваш адрес' })}
                  error={errors.address}
                  placeholder="Введите ваш адрес"
                />
              </div>
            </div>
          )}
          <div className={s.block}>
            <div className={s.blockTitle}>Способ оплаты:</div>
            <Controller
              as={<PaymentMethodInput depositBalance={viewer.user?.depositBalance || 0} />}
              control={control}
              name="paymentMethod"
            />
          </div>
          <div className={s.block}>
            <div className={s.blockTitle}>Дата и время доставки:</div>
            <Controller
              as={<DeliveryTimePicker intervals={res.data.deliveryIntervals} />}
              control={control}
              name="deliveryAt"
            />
          </div>
          <div className={s.block}>
            <div className={s.blockTitle}>Ваш комментарий / промокод:</div>
            <TextField name="comment" register={register} placeholder="Ваш комментарий / промокод" Tag="textarea" />
          </div>
          <div className="flex mb-8">
            <CheckboxInput
              name="asap"
              register={register}
              label="Доставить как можно скорее"
              description="Мы постараемся доставить ваш заказ как можно раньше."
              className="mr-8"
            />
            <CheckboxInput
              name="withoutOverweight"
              register={register}
              label="Без перевесов"
              description="Фактический вес продукта не будет превышать вес, указанный в заказе."
              className="mr-8"
            />
            <CheckboxInput
              name="ecoPack"
              register={register}
              label="Eco-упаковка"
              description="Вы можете выбрать эко-упаковку продуктов, в которой предпочтение отдается биоразлагаемым и перерабатываемым материалам (бумага, картон, стекло), а использование пластика сводится к минимуму. Эко-упаковка не наносит вред окружающей среде, но увеличивает риск возможного повреждения продуктов при транспортировке."
            />
          </div>
          {errorMessage && <div className="text-red text-center mb-8">{errorMessage}</div>}
          {canPlaceOrder && (
            <button type="submit" className="button button-primary button-large w-64">
              Заказать
            </button>
          )}
        </form>
        {mutationState.fetching && <Loading inModal />}
      </>
    </Wrapper>
  );
};

export const query = /* urqlGraphQL */ `
  query CheckoutQuery($token: String, $ids: [Int!]!) {
    viewer(token: $token){
      token
      user {
        depositBalance
        mainPhone
        lastOrder {
          ecoPack
          withoutOverweight
          asap
          paymentMethod
        }
        ...ActiveAddress_data
      }    
    }
    products: productsByIds(ids: $ids) {
      rowId
      price
      oldPrice
    }
    deliveryIntervals {
      value
      ...DeliveryTimePicker_interval
    }
    deliveryCosts{
      itemSum
      ...CartSummary_data
    }
  }
`;

export const mutation = /* urqlGraphQL */ `
  mutation CheckoutMutation($token: String!, $input: PlaceOrderInput!, $yandex: Boolean!, $clearCart: Boolean) {
    placeOrder(token: $token, input: $input, clearCart: $clearCart) {
      __typename

      ... on PlaceOrderPayload {
        orderId
        onlinePaymentUrl
        authCodeSended
        totalSum
        yandexKassaConfirmationToken @include(if: $yandex)
        viewer {
          token 
          user {
            depositBalance
            mainPhone
            ...ActiveAddress_data
            lastOrder {
              ecoPack
              withoutOverweight
              asap
              paymentMethod
            }
          }
        }
      }

      ... on ErrorPayload {
        message
      }
    }
  }
`;
