import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/browser';
import { useMyPasswordMutation, ChangePasswordInput } from '__generated__/urql';
import { useGlobal } from './hooks';
import { TextField } from './TextField';
import { Loading } from './Loading';

type FormData = ChangePasswordInput & {
  newPasswordConfirm: string;
};

export const MyPassword = () => {
  const [token] = useGlobal((s) => s.token);

  if (!token) {
    return null;
  }

  const [mutationState, mutate] = useMyPasswordMutation();
  const { register, handleSubmit, errors, watch } = useForm<FormData>();
  const [errorMessage, setErrorMessage] = React.useState('');

  const onSubmit = handleSubmit(async ({ newPassword }) => {
    try {
      const res = await mutate({ token, input: { newPassword } });

      setErrorMessage('');

      if (res.data?.result?.__typename === 'ErrorPayload') {
        setErrorMessage(res.data.result.message);
        Sentry.captureException(res.data.result.message);
      }
    } catch (e) {
      setErrorMessage('Произошла внутренняя ошибка');
      Sentry.captureException(e);
    }
  });

  return (
    <div className="flex flex-col flex-grow">
      <div className="text-black text-3xl font-bold leading-none text-center mb-8">Изменить пароль</div>
      <form onSubmit={onSubmit} className="flex flex-col flex-grow">
        <div className="flex mb-4">
          <TextField
            type="password"
            name="newPassword"
            label="Введите новый пароль"
            register={register({ required: 'Введите новый пароль' })}
            error={errors.newPassword}
            className="w-1/2 mr-8"
          />
          <TextField
            type="password"
            name="newPasswordConfirm"
            label="Подтвердите новый пароль"
            register={register({
              required: 'Подтвердите новый пароль',
              validate: (value) => value === watch('newPassword') || 'Пароли не совпадают',
            })}
            error={errors.newPasswordConfirm}
            className="w-1/2"
          />
        </div>
        {errorMessage && <div className="text-red text-center">{errorMessage}</div>}
        <button type="submit" className="button button-primary button-large w-64 mx-auto mt-auto">
          Изменить пароль
        </button>
      </form>
      {mutationState.fetching && <Loading inModal />}
    </div>
  );
};

export const mutation = /* urqlGraphQL */ `
  mutation MyPassword($token: String!, $input: ChangePasswordInput!) {
    result: changePassword(token: $token, input: $input) {
      __typename
      
      ... on ErrorPayload {
        message
      }
    }
  }
`;
