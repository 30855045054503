import * as React from 'react';
import { useForm } from 'react-hook-form';
import { AddressInput, useMyNewAddressMutation } from '__generated__/urql';
import { getQueryParams } from 'utils';
import { TextField, TextFieldTag } from './TextField';
import { useGlobal } from './hooks';
import { Loading } from './Loading';
import s from './MyNewAddress.module.css';

type Props = {
  readonly toTab: (t: string) => void;
};

type Field = {
  readonly name: keyof AddressInput;
  readonly label: string;
  readonly Tag?: TextFieldTag;
};

const fields: Array<Field> = [
  {
    name: 'city',
    label: 'Город',
  },
  {
    name: 'street',
    label: 'Улица',
  },
  {
    name: 'house',
    label: 'Дом',
  },
  {
    name: 'building',
    label: 'Строение',
  },
  {
    name: 'entrance',
    label: 'Подъезд',
  },
  {
    name: 'appartment',
    label: 'Квартира',
  },
  {
    name: 'buildingFloor',
    label: 'Этаж',
  },
  {
    name: 'intercomCode',
    label: 'Код домофона',
  },
  {
    name: 'comment',
    label: 'Коментарий',
    Tag: 'textarea',
  },
];

export const MyNewAddress = ({ toTab }: Props) => {
  const [token, globalActions] = useGlobal((state) => state.token);

  if (!token) {
    return null;
  }

  const [mutationState, mutate] = useMyNewAddressMutation();
  const { handleSubmit, register } = useForm<AddressInput>();
  const [errorMessage, setErrorMessage] = React.useState('');

  const q = getQueryParams();

  const onSubmit = handleSubmit(async (input) => {
    try {
      const res = await mutate({ token, input });
      if (res.data?.result?.__typename === 'CreateAddressPayload') {
        if (typeof q.backTo === 'string') {
          globalActions.setModal(q.backTo);
          window.history.replaceState(null, '', `?modal=${q.backTo}`);
        } else {
          toTab('addresses');
        }
      } else if (res.data?.result?.__typename === 'ErrorPayload') {
        setErrorMessage(res.data.result.message);
      } else {
        throw new Error();
      }
    } catch (error) {
      setErrorMessage('Произошла ошибка');
    }
  });

  return (
    <div className="flex flex-col flex-grow">
      <div className="text-black text-3xl font-bold leading-none text-center mb-8">Добавить адрес</div>
      <form onSubmit={onSubmit} className="flex flex-col flex-grow">
        <div className="grid grid-cols-3 gap-4 mb-8">
          {fields.map((f) => (
            <TextField key={f.name} name={f.name} register={register} label={f.label} Tag={f.Tag} className={s.field} />
          ))}
        </div>
        {errorMessage && <div className="text-red text-center mb-8">{errorMessage}</div>}
        <button type="submit" className="button button-primary button-large w-64 mx-auto">
          Сохранить изменения
        </button>
      </form>
      {mutationState.fetching && <Loading inModal />}
    </div>
  );
};

export const mutation = /* urqlGraphQL */ `
  mutation MyNewAddress($token: String!, $input: AddressInput!) {
    result: createAddress(token: $token, input: $input) {
      __typename

      ... on ErrorPayload {
        message
      }

      ... on CreateAddressPayload {
        address {
          id
        }

        viewer {
          user {
            id

            activeAddress {
              ...Address_data
            }

            addresses {
              ...MyAddressItem_data
            }
          }
        }
      }
    }
  }
`;
