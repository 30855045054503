import * as React from 'react';
import * as Sentry from '@sentry/browser';
import emptyImg from 'images/addressEmpty.png';
import emptyImgRetina from 'images/addressEmpty@2x.png';
import {
  MyAddresses_DataFragment,
  useMyAddressesUpdateProfileMutation,
  useMyAddressesDeleteAddressMutation,
} from '__generated__/urql';
import { getQueryParams } from 'utils';
import { MyAddressItem } from './MyAddressItem';
import { useGlobal } from './hooks';
import { Loading } from './Loading';
import { ModalLink } from './ModalLogic';

type Props = {
  readonly data: MyAddresses_DataFragment;
  readonly toTab: (t: string) => void;
};

export const MyAddresses = ({ data, toTab }: Props) => {
  const [token, globalActions] = useGlobal((s) => s.token);

  if (!token) {
    return null;
  }

  const [updateProfileMutationState, updateProfile] = useMyAddressesUpdateProfileMutation();
  const [deleteAddressMutationState, deleteAddress] = useMyAddressesDeleteAddressMutation();
  const [errorMessage, setErrorMessage] = React.useState('');

  const q = getQueryParams();

  const onUpdateProfile = async (rowId: number) => {
    try {
      const res = await updateProfile({
        token,
        // @ts-ignore
        input: {
          activeAddressId: rowId,
        },
      });

      if (typeof q.backTo === 'string' && res.data && res.data.result.__typename === 'User') {
        globalActions.setModal(q.backTo);
        window.history.replaceState(null, '', `?modal=${q.backTo}`);
      }

      if (res.data && res.data.result.__typename === 'ErrorPayload') {
        setErrorMessage(res.data.result.message);
        Sentry.captureException(res.data.result.message);
      }
    } catch (e) {
      setErrorMessage('Произошла внутренняя ошибка');
      Sentry.captureException(e);
    }
  };

  const onDeleteAddress = async (rowId: number) => {
    try {
      const res = await deleteAddress({ token, rowId });

      if (res.data && res.data.deleteAddress.__typename === 'ErrorPayload') {
        setErrorMessage(res.data.deleteAddress.message);
        Sentry.captureException(res.data.deleteAddress.message);
      }
    } catch (e) {
      setErrorMessage('Произошла внутренняя ошибка');
      Sentry.captureException(e);
    }
  };

  return (
    <>
      {data.addresses.length === 0 && (
        <div className="flex flex-col items-center text-center text-black">
          <img src={emptyImg} srcSet={`${emptyImgRetina} 2x`} alt="" className="mb-6" />
          <div className="text-3xl font-bold leading-none mb-4">Укажите куда доставлять ваши заказы</div>
          <div className="leading-5">У нашей доставки очень широкая зона покрытия.</div>
          <div className="leading-5">
            С более точной информацией можно ознакомиться на странице{' '}
            <ModalLink to="/delivery" className="text-orange underline hover:no-underline focus:no-underline">
              о доставке
            </ModalLink>
            .
          </div>
        </div>
      )}
      {data.addresses.length > 0 && (
        <>
          <div className="text-black text-3xl font-bold leading-none text-center mb-8">Выберите активный адрес</div>
          <div>
            {data.addresses.map((a) => (
              <MyAddressItem
                key={a.rowId}
                data={a}
                onChange={() => onUpdateProfile(a.rowId)}
                onDelete={() => onDeleteAddress(a.rowId)}
                active={data.activeAddress ? data.activeAddress.rowId === a.rowId : false}
              />
            ))}
          </div>
        </>
      )}
      <div className="flex justify-center pt-6 mt-auto">
        <button type="button" onClick={() => toTab('newAddress')} className="button button-primary button-large w-64">
          Добавить новый адрес
        </button>
      </div>
      {errorMessage && <div className="text-red text-center mt-4">{errorMessage}</div>}
      {(updateProfileMutationState.fetching || deleteAddressMutationState.fetching) && <Loading inModal />}
    </>
  );
};

export const fragment = /* urqlGraphQL */ `
  fragment MyAddresses_data on User {
    activeAddress {
      rowId
    }

    addresses {
      id
      rowId
      ...MyAddressItem_data
    }
  }
`;

export const updateProfileMutation = /* urqlGraphQL */ `
  mutation MyAddressesUpdateProfile($token: String!, $input: ChangeProfileInput!) {
    result: changeProfile(token: $token, input: $input) {
      __typename

      ... on ErrorPayload {
        message
      }

      ... on User {
        rowId
        email
        firstName
        lastName
        mainPhone
        depositBalance
      }
    }
  }
`;

export const deleteAddressMutation = /* urqlGraphQL */ `
  mutation MyAddressesDeleteAddress($token: String!, $rowId: Int!) {
    deleteAddress(token: $token, rowId: $rowId) {
      __typename

      ... on ErrorPayload {
        message
      }

      ... on DeleteAddressPayload {
        viewer {
          user {
            id
            activeAddress {
              rowId
            }

            addresses {
              ...MyAddressItem_data
            }
          }
        }
      }
    }
  }
`;
