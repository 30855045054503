import * as React from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { ModalLogic } from './ModalLogic';
import '../global.css';
import '../grid.css';
import '../mf-grid.css';

export const Layout: React.FC = ({ children }) => (
  <div className="min-h-screen flex flex-col">
    <Header />
    <div className="flex-grow">{children}</div>
    <Footer />
    <ModalLogic />
  </div>
);
