import * as React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import logoImg from 'images/logo.png';
import retinaLogoImg from 'images/logo@2x.png';
import { ClosestDelivery } from './ClosestDelivery';
import { ModalButton } from './ModalLogic';
import { useGlobal } from './hooks';
import SearchGrayIcon from './icons/SearchGray';
import SearchOrangeIcon from './icons/SearchOrange';
import AccountIcon from './icons/AccountGray';
import CartOrangeIcon from './icons/CartOrange';
import CartWhiteIcon from './icons/CartWhite';
import s from './Header.module.css';

export const Header = () => {
  const [cartProductsLength] = useGlobal((state) => state.cart.addedIds.length);

  return (
    <header
      className="flex items-center py-6 px-4 xl:py-4 lg:flex-col lg:items-stretch lg:px-0"
      style={{ boxShadow: '0px 0.5px 0px rgba(3, 10, 26, 0.16)' }}
    >
      <div className="flex items-center flex-1 lg:px-4 lg:mb-4">
        <Link to="/" className="w-40 mr-8 xl:w-28 xl:mr-6 lg:w-32 lg:mr-7 md:mr-5">
          <img src={logoImg} srcSet={`${retinaLogoImg} 2x`} alt="" />
        </Link>
        <div className="leading-5 mr-8 xl:text-sm xl:mr-6 lg:mr-7 md:mr-5">
          <div className="text-mf-gray-1">Ближайшая доставка:</div>
          <ClosestDelivery className="text-mf-black" />
        </div>
        <div className={s.search}>
          <SearchGrayIcon className={cn(s.searchIcon, s.searchGrayIcon)} />
          <SearchOrangeIcon className={cn(s.searchIcon, s.searchOrangeIcon)} />
          <input type="text" placeholder="Что ищем сегодня?" className={s.searchField} />
        </div>
      </div>
      <div className="flex items-center lg:pr-4">
        <Link to="/about" className={s.link} activeClassName={s.linkActive} partiallyActive>
          О сервисе
        </Link>
        <Link to="/reviews" className={s.link} activeClassName={s.linkActive} partiallyActive>
          Отзывы
        </Link>
        <Link to="/deposit" className={s.link} activeClassName={s.linkActive} partiallyActive>
          Депозит
        </Link>
        <Link to="/delivery" className={cn(s.link, 'lg:mr-auto')} activeClassName={s.linkActive} partiallyActive>
          Доставка
        </Link>
        <ModalButton to="my" className="flex items-center justify-center w-14 h-14 ml-4 mr-2 xl:ml-2 lg:ml-0 lg:mr-4">
          <AccountIcon size={32} />
        </ModalButton>
        <ModalButton
          to="cart"
          className="group relative flex items-center justify-center w-14 h-14 border border-mf-orange rounded-lg transition-colors duration-300 hover:bg-mf-orange"
        >
          <CartOrangeIcon size={32} className="transition-opacity duration-300 group-hover:opacity-0" />
          <CartWhiteIcon
            size={32}
            className="transition-opacity duration-300 absolute opacity-0 group-hover:opacity-100"
          />
          {cartProductsLength > 0 && (
            <div className="absolute right-1 top-1 flex items-center justify-center w-5 h-5 text-white bg-mf-orange rounded-full text-xs font-bold leading-none transition-colors duration-300 group-hover:text-mf-orange group-hover:bg-white">
              {cartProductsLength}
            </div>
          )}
        </ModalButton>
      </div>
    </header>
  );
};
