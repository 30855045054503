import * as React from 'react';
import emptyImg from 'images/historyEmpty.png';
import emptyRetinaImg from 'images/historyEmpty@2x.png';
import { useMyHistoryQuery } from '__generated__/urql';
import { MyHistoryOrder } from './MyHistoryOrder';
import { ModalLink } from './ModalLogic';
import { useGlobal } from './hooks';
import { Loading } from './Loading';

const LIMIT_START = 5;
const LIMIT_END = 99;

export const MyHistory = () => {
  const [token] = useGlobal((state) => state.token);
  const [limit, showAll] = React.useReducer(() => LIMIT_END, LIMIT_START);
  const [res] = useMyHistoryQuery({
    variables: {
      token: token || 'guest',
      limit,
    },
    pause: !token,
  });

  if (!token) {
    return null;
  }

  if (res.fetching) {
    return <Loading inModal />;
  }

  if (res.error) {
    return <div>Произошла ошибка</div>;
  }

  if (res.data && res.data.viewer.user) {
    const { user } = res.data.viewer;

    return (
      <>
        {user.orders.length === 0 && (
          <div className="flex flex-col items-center text-center">
            <img src={emptyImg} srcSet={`${emptyRetinaImg} 2x`} alt="" className="mb-6" />
            <div className="text-black text-3xl font-bold leading-none mb-8">Вы еще ничего не заказывали</div>
            <ModalLink to="/catalog" className="button button-secondary button-large w-64">
              В каталог
            </ModalLink>
          </div>
        )}
        {user.orders.length > 0 && (
          <div>
            {user.orders.map((o) => (
              <MyHistoryOrder key={o.id} data={o} className="mb-6" />
            ))}
          </div>
        )}
        {user.orders.length > 0 && user.orderCount > limit && (
          <button onClick={showAll} type="button" className="button button-primary button-large w-full mt-auto">
            Показать все
          </button>
        )}
      </>
    );
  }

  return null;
};

export const viewerQuery = /* urqlGraphQL */ `
  query MyHistory($token: String!, $limit: Int) {
    viewer(token: $token) {
      user {
        orderCount
        orders(limit: $limit) {
          id  
          ...MyHistoryOrder_data
        }
      }
    }
  }
`;
