import * as React from 'react';
import { graphql } from 'gatsby';

type Props = {
  readonly data: GatsbyTypes.Address_DataFragment;
  readonly short?: boolean;
};

type Part = {
  readonly prefix: string;
  readonly key: keyof GatsbyTypes.Address_DataFragment;
};

const shortParts: Part[] = [
  { prefix: 'ул. ', key: 'street' },
  { prefix: 'д. ', key: 'house' },
];

const allParts: Part[] = [
  ...shortParts,
  { prefix: 'стр. ', key: 'building' },
  { prefix: 'подъезд ', key: 'entrance' },
  { prefix: 'этаж ', key: 'buildingFloor' },
  { prefix: 'кв. ', key: 'appartment' },
  { prefix: '', key: 'comment' },
];

export const Address = ({ data, short }: Props) => {
  const parts = short ? shortParts : allParts;

  return (
    <>
      {data.city && data.city !== '' && <span>{data.city}</span>}
      {parts.map((p) => {
        if (!data[p.key] || data[p.key] === '') {
          return null;
        }

        return (
          <span key={p.key}>
            , {p.prefix}
            {data[p.key]}
          </span>
        );
      })}
    </>
  );
};

export const query = graphql`
  fragment Address_data on Api_Address {
    id
    rowId
    deleted
    city
    street
    house
    comment
    entrance
    building
    buildingFloor
    appartment
    intercomCode
  }
`;
