import React from 'react';
import { graphql } from 'gatsby';
import { Price } from './Price';

export type Props = {
  readonly data: GatsbyTypes.OrderDetails_DataFragment;
};

const removeQuantityFromName = (s: string) => s.replace(/~.*?$/g, '');

export const OrderDetails = ({ data }: Props) => (
  <div>
    {data.items.map((item, index) => {
      const orderedTotal = item.orderedQuantity * item.price;
      const total = (item.quantity || 0) * item.price;
      const key = item.productId ? item.productId + index : index; // TODO продукты повторяются
      return (
        <div key={key} className="border-t pt-4 mb-4 text-sm text-right">
          <div className="text-lg pb-2 text-left">{removeQuantityFromName(item.product?.name || 'Продукт удален')}</div>
          <div className="flex flex-row">
            <div className="flex flex-1 flex-col justify-center mf-text-gray-1">&nbsp;</div>
            <div className="flex flex-1 flex-col justify-center mf-text-gray-1">Кол</div>
            <div className="flex flex-1 flex-col justify-center mf-text-gray-1">Цена</div>
            <div className="flex flex-1 flex-col justify-center mf-text-gray-1">Сумма</div>
            <div className="flex flex-1 flex-col justify-center mf-text-gray-1">Разница</div>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-1 flex-col justify-center mf-text-gray-1">
              <div>Заказ</div>
              <div>По факту</div>
            </div>
            <div className="flex flex-1 flex-col justify-center">
              <div>{item.product.name ? `${item.orderedQuantity} ${item.product.stockUnit}` : '-'}</div>
              <div>{item.product.name && item.quantity ? `${item.quantity} ${item.product.stockUnit}` : '-'}</div>
            </div>
            <div className="flex flex-1 flex-col justify-center">
              <Price value={item.price} />
            </div>
            <div className="flex flex-1 flex-col justify-center">
              <Price value={orderedTotal} />
              <Price value={total} />
            </div>
            <div className="flex flex-1 flex-col justify-center">
              <Price value={total - orderedTotal} />
            </div>
          </div>
        </div>
      );
    })}
  </div>
);

export const query = graphql`
  fragment OrderDetails_data on Api_Order {
    items {
      productId
      orderedQuantity
      quantity
      price
      product {
        name
        stockUnit
      }
    }
  }
`;
