import * as React from 'react';
import classnames from 'classnames';
import { calcDiscount } from 'utils';

type Props = {
  readonly price: number;
  readonly oldPrice: number | null;
  readonly className?: string;
};

export const DiscountTag = ({ price, oldPrice, className }: Props) => {
  const discount = calcDiscount(price, oldPrice);

  if (discount) {
    return (
      <span
        className={classnames('flex items-center h-6 text-white bg-orange text-sm font-bold px-2', className)}
        style={{ borderRadius: 32 }}
      >
        -{discount}%
      </span>
    );
  }

  return null;
};
