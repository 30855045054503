import * as React from 'react';
import plural from 'plural-ru';
import { CartSummary_DataFragment } from '__generated__/urql';
import { Price } from './Price';
import { ClosestDelivery } from './ClosestDelivery';

type Props = {
  readonly data: CartSummary_DataFragment[];
  readonly canPlaceOrder: boolean;
  readonly itemSum: number;
  readonly addedIdsLength: number;
};

const isRightDeliveryCost = (item: Props['data'][0], cartItemSum: number) => {
  if (item.activeRange) {
    return false;
  }

  return cartItemSum > item.itemSum;
};

const getDeliveryCost = (items: Props['data'], cartItemSum: number) => {
  const reversedItems = [...items].reverse();
  const item = reversedItems.find((c) => c && isRightDeliveryCost(c, cartItemSum)) || items[0];
  return item.value;
};

export const CartSummary = ({ data, canPlaceOrder, itemSum, addedIdsLength }: Props) => {
  const deliveryCost = getDeliveryCost(data, itemSum);
  const total = itemSum + deliveryCost;

  return (
    <div className="flex justify-between py-6 px-8 border-b border-gray-90">
      <div>
        {canPlaceOrder && (
          <div className="text-black text-xl font-bold leading-6 mb-1">
            Сумма заказа: <Price value={total} />
          </div>
        )}
        <div className="text-black leading-5">
          {addedIdsLength} {plural(addedIdsLength, 'товар', 'товара', 'товаров')}: <Price value={itemSum} />{' '}
          {canPlaceOrder && (
            <>
              + Доставка: <Price value={deliveryCost} allowFree />
            </>
          )}
        </div>
      </div>
      <div>
        <div className="text-gray-48 font-bold leading-6 mb-1">Ближайшая доставка:</div>
        <ClosestDelivery className="text-black leading-5" />
      </div>
    </div>
  );
};

export const fragment = /* urqlGraphQL */ `
  fragment CartSummary_data on DeliveryCost {
    value 
    itemSum
    activeRange {
      from
      to
    }
  }
`;
