import * as React from 'react';
import { formatMoney, NBSP } from 'utils';

type Props = {
  readonly value: number;
  readonly className?: string;
  readonly style?: React.CSSProperties;
  readonly allowFree?: boolean;
};

const getText = (value: number, allowFree?: boolean) => {
  if (value !== 0) {
    return `${formatMoney(value)}${NBSP}₽`;
  }
  if (allowFree) {
    return 'БЕСПЛАТНО';
  }
  return `0${NBSP}₽`;
};

export const Price = ({ value, className, allowFree, style }: Props) => {
  const cn = value === 0 ? `${className} font-bold text-orange` : className;

  if (typeof value !== 'number' || Number.isNaN(value)) {
    return null;
  }

  return (
    <span className={cn} style={style}>
      {getText(value, allowFree)}
    </span>
  );
};
