/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import classnames from 'classnames';
import { PaymentMethod } from '__generated__/urql';
import CoinsBlack from './icons/CoinsBlack';
import CoinsWhite from './icons/CoinsWhite';
import CreditCardBlack from './icons/CreditCardBlack';
import CreditCardWhite from './icons/CreditCardWhite';
import GlobeBlack from './icons/GlobeBlack';
import GlobeWhite from './icons/GlobeWhite';
import DepositBlack from './icons/DepositBlack';
import DepositWhite from './icons/DepositWhite';
import { Price } from './Price';
import s from './PaymentMethodInput.module.css';

type ItemProps = {
  readonly icon: string;
  readonly label: string;
  readonly onClick: () => void;
  readonly active: boolean;
  readonly className?: string;
};

const icons: {
  [key in string]: {
    Black: any;
    White: any;
  };
} = {
  coins: {
    Black: CoinsBlack,
    White: CoinsWhite,
  },
  creditCard: {
    Black: CreditCardBlack,
    White: CreditCardWhite,
  },
  globe: {
    Black: GlobeBlack,
    White: GlobeWhite,
  },
  deposit: {
    Black: DepositBlack,
    White: DepositWhite,
  },
};

const Item: React.FC<ItemProps> = ({ icon, label, onClick, active, children, className }) => {
  const Icon = icons[icon];

  return (
    <button type="button" onClick={onClick} className={classnames(s.item, active && s.active, className)}>
      <Icon.Black className={classnames(s.icon, s.iconBlack)} />
      <Icon.White className={classnames(s.icon, s.iconWhite)} />
      {label}
      {children}
    </button>
  );
};

type Props = {
  readonly depositBalance: number;
  readonly value?: string;
  readonly onChange?: (v: string) => void;
};

export const PaymentMethodInput = ({ depositBalance, value, onChange }: Props) => {
  const createItemProps = (v: PaymentMethod) => ({
    onClick: () => onChange!(v),
    active: v === value,
  });

  return (
    <div className="grid grid-cols-2 gap-4">
      <Item icon="coins" label="Наличные курьеру" {...createItemProps(PaymentMethod.BY_CASH)} />
      <Item icon="creditCard" label="Картой курьеру" {...createItemProps(PaymentMethod.BY_CARD)} />
      <Item icon="globe" label="Онлайн" {...createItemProps(PaymentMethod.ONLINE)} />
      <Item icon="deposit" label="Депозит" {...createItemProps(PaymentMethod.DEPOSIT)}>
        {depositBalance > 0 && (
          <span className="text-sm leading-none ml-auto">
            На вашем депозите <Price value={depositBalance} />.
          </span>
        )}
      </Item>
    </div>
  );
};
