import * as React from 'react';
import { FieldError } from 'react-hook-form';
import classnames from 'classnames';

export type TextFieldTag = 'input' | 'textarea';

type OwnProps = {
  readonly name: string;
  readonly register: (ref: HTMLInputElement | HTMLTextAreaElement) => void;
  readonly label?: string;
  readonly error?: FieldError;
  readonly className?: string;
  readonly inputClassName?: string;
  readonly Tag?: TextFieldTag;
};

type Props = React.HTMLProps<HTMLInputElement> & React.HTMLProps<HTMLTextAreaElement> & OwnProps;

// TODO: это какая-то лажа, не должен быть простой компонент таким трешовым

export const TextField = ({
  name,
  label,
  register,
  error,
  className,
  inputClassName,
  Tag = 'input',
  ...inputProps
}: Props) => (
  <label htmlFor={name} className={classnames('block', className)}>
    {label && <span className="block text-gray-64 leading-5 mb-1">{label}</span>}
    <Tag
      name={name}
      id={name}
      // @ts-ignore
      ref={register}
      className={classnames(
        'w-full text-black bg-gray-95 rounded-lg py-3 px-4 placeholder-gray-48 transition-colors duration-300',
        Tag === 'textarea' && 'resize-none',
        inputClassName,
      )}
      // @ts-ignore
      style={{ height: Tag === 'textarea' && 136 }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...inputProps}
    />
    {error && error.message && <span className="block text-red text-sm leading-5 mt-1">{error.message}</span>}
  </label>
);
