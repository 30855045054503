import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { formatNumberTime, formatDay } from 'utils';

type Props = {
  readonly className?: string;
};

const labels: { [key in string]: string } = {
  '0': 'Сегодня',
  '1': 'Завтра',
  '2': 'Послезавтра',
};

export const ClosestDelivery = ({ className }: Props) => {
  const data = useStaticQuery<GatsbyTypes.ClosestDeliveryQuery>(
    graphql`
      query ClosestDelivery {
        api {
          deliveryIntervals {
            value
            from
            to
          }

          now
        }
      }
    `,
  );

  const firstInterval = data.api.deliveryIntervals[0];
  const diff = differenceInCalendarDays(new Date(firstInterval.value), new Date(data.api.now));
  const label = labels[diff] || formatDay(firstInterval.value);

  return (
    <div className={className}>
      {label} с {formatNumberTime(firstInterval.from)} до {formatNumberTime(firstInterval.to)}
    </div>
  );
};
