import * as React from 'react';
import img from 'images/depositEmpty.png';
import imgRetina from 'images/depositEmpty@2x.png';
import { MyDeposit_DataFragment } from '__generated__/urql';
import { DepositTransaction } from './DepositTransaction';
import { Price } from './Price';

type Props = {
  readonly data: MyDeposit_DataFragment;
};

export const MyDeposit = ({ data }: Props) => (
  <>
    {data.depositTransactions.length === 0 ? (
      <div className="flex flex-col items-center justify-center text-center text-black">
        <img src={img} srcSet={`${imgRetina} 2x`} alt="" className="mb-6" />
        <div className="text-3xl font-bold leading-none mb-4">Депозит пуст</div>
        <div className="leading-5 -mx-4 mb-1">
          Совершайте покупки в MoscowFresh с выгодой! Наша система депозитов поможет сэкономить не только время, но и
          бюджет. Оформляя депозит, вы получаете бесплатную доставку всех заказов и до 15% от суммы пополнения.
          Например, при пополнении депозита на 10 000 ₽, мы увеличиваем сумму депозита до 10 500 ₽. Чем больше сумма
          пополнения, тем больше выгода.
        </div>
        <div className="font-bold leading-5 mb-6">Чтобы внести депозит, свяжитесь с нашим менеджером.</div>
      </div>
    ) : (
      <>
        <div className="flex items-center justify-between mb-6">
          <div>
            <div className="text-black text-xl leading-6 mb-2">Ваш депозит:</div>
            <Price value={data.depositBalance} className="text-orange text-3xl font-bold leading-none" />
          </div>
        </div>
        {/* TODO duplicate key item.id (dev api) */}
        {data.depositTransactions.map((d, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <DepositTransaction key={i} data={d} />
        ))}
      </>
    )}
  </>
);

export const fragment = /* urqlGraphQL */ `
  fragment MyDeposit_data on User {
    depositBalance
    depositTransactions {
      ...DepositTransaction_data
    }
  }
`;
