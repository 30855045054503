import * as React from 'react';
import cn from 'classnames';
import { ModalLink } from './ModalLogic';
import { useGlobal } from './hooks';
import HeadphonesIcon from './icons/HeadphonesWhite';
import CloseWhiteIcon from './icons/CloseWhite';
import s from './ModalTop.module.css';

type Props = {
  readonly title: string;
  readonly type: 'big' | 'small';
  readonly className?: string;
};

export const ModalTop: React.FC<Props> = ({ title, type, className, children }) => {
  const [, globalActions] = useGlobal(() => null);

  const onClose = () => {
    globalActions.setModal(null);
    window.history.replaceState(null, '', '?modal=null');
  };

  return (
    <div className={cn('relative flex items-center h-14 bg-black rounded-t-lg', type === 'small' && 'pl-8', className)}>
      {children}
      <div
        className={cn(
          'text-white text-lg font-bold leading-6',
          type === 'big' && 'absolute left-1/2 transform -translate-x-1/2',
        )}
      >
        {title}
      </div>
      <ModalLink to="/support" className={s.link}>
        Поддержка <HeadphonesIcon size={16} className="ml-2" />
      </ModalLink>
      <button
        type="button"
        onClick={onClose}
        className="p-4 opacity-50 transition-opacity duration-300 hover:opacity-100"
      >
        <CloseWhiteIcon />
      </button>
    </div>
  );
};
