import * as React from 'react';
import cn from 'classnames';
import appStore from 'images/appStoreWhite.png';
import retinaAppStore from 'images/appStoreWhite@2x.png';
import googlePlay from 'images/googlePlayWhite.png';
import retinaGooglePlay from 'images/googlePlayWhite@2x.png';
import s from './AppLink.module.css';

type Props = {
  readonly type: 'appStore' | 'googlePlay';
  readonly className?: string;
};

const images = {
  appStore: {
    normal: appStore,
    retina: retinaAppStore,
  },
  googlePlay: {
    normal: googlePlay,
    retina: retinaGooglePlay,
  },
};

export const AppLink = ({ type, className }: Props) => {
  const image = images[type];

  return (
    <a href="/app" className={cn(s.root, className)}>
      <img src={image.normal} srcSet={`${image.retina} 2x`} alt="" />
    </a>
  );
};
