/* eslint-disable import/no-extraneous-dependencies */
import * as React from 'react';
import classnames from 'classnames';
import plural from 'plural-ru';
import qs from 'query-string';
import { formatDay } from 'utils';
import { MyHistoryOrder_DataFragment, OrderStatus } from '__generated__/urql';
import { Price } from './Price';
import { OrderDetails } from './OrderDetails';
import { ModalLink } from './ModalLogic';
import { useGlobal } from './hooks';
import s from './MyHistoryOrder.module.css';

type Props = {
  readonly data: MyHistoryOrder_DataFragment;
  readonly className?: string;
};

export const statuses: { [key in OrderStatus]: string } = {
  CANCELED: 'Отменен',
  COMPLETED: 'Выполнен',
  DELIVERED: 'Доставлен',
  PACKING: 'Собирается',
  PENDING: 'Ожидает сборку',
  SHIPPED: 'Отправлен',
};

export const MyHistoryOrder = ({ data, className }: Props) => {
  const [detailsShown, showDetails] = React.useState(false);
  const [, globalActions] = useGlobal(() => null);

  const time = (data.deliveryAt || data.deliveredAt) as string;
  const itemsLength = data.items.length;

  const productsIds = data.items.map((p) => p.product.rowId);
  const qq = qs.stringify({ ids: productsIds, title: `Продукты из заказа № ${data.shopId}` }, { arrayFormat: 'comma' });
  const detailsExists = data.status !== 'PENDING' && data.status !== 'PACKING';

  const handleShowDetails = () => showDetails((v) => !v);

  const products = data.items.map((i) => i.product).filter((p) => p.status === 'VISIBLE');

  const handleRepeatOrder = () => {
    products.forEach((p) => {
      globalActions.cart.increaseQuantity(p);
      globalActions.setModal('cart');
      window.history.replaceState(null, '', '?modal=cart');
    });
  };

  return (
    <div className={classnames(s.root, className)}>
      <div className="flex pb-6">
        {products.length > 0 && (
          <ModalLink to={`/products?${qq}`} className={s.images}>
            {products.slice(0, 2).map((p) => (
              <img
                key={p.rowId}
                src={p.imageUrl}
                srcSet={`${p.retinaImageUrl} 2x`}
                alt=""
                data-sizes="auto"
                className="lazyload"
                width="144"
              />
            ))}
            <span className={s.imagesLabel}>
              {itemsLength} {plural(itemsLength, 'товар', 'товара', 'товаров')}
            </span>
          </ModalLink>
        )}
        <div className="flex flex-col w-full">
          <div className="flex mb-3">
            {time && <div className="text-black text-xl font-bold leading-6 mr-4">{formatDay(time)}</div>}
            <div className={classnames(s.status, data.status === 'CANCELED' ? s.canceled : s.success)}>
              {statuses[data.status] || data.status}
            </div>
            <div className="text-black leading-6">
              <span className="text-gray-64">№</span> {data.shopId}
            </div>
          </div>
          {data.asap && <div className="text-sm leading-4">Доставьте как можно скорее!</div>}
          <div className="flex items-end mt-auto">
            <div className="w-full leading-none mr-12">
              <div className="flex justify-between text-sm mb-3">
                <div className="text-gray-64">Доставка</div>
                <Price allowFree={data.deliveryCost === 0} value={data.deliveryCost} />
              </div>
              <div className="flex justify-between text-sm mb-3">
                <div className="text-gray-64">Сумма продуктов</div>
                <Price value={data.itemSum} />
              </div>
              <div className="flex justify-between">
                <div className="font-bold">Итого:</div>
                <Price value={data.totalSum} />
              </div>
            </div>
            <div>
              <div className="flex mb-2">
                <ModalLink to={`/products?${qq}`} className="button button-secondary button-normal px-3 mr-4">
                  Продукты
                </ModalLink>
                <button
                  type="button"
                  onClick={handleShowDetails}
                  disabled={!detailsExists}
                  className="button button-primary button-normal px-4"
                >
                  Чек
                </button>
              </div>
              <button
                type="button"
                onClick={handleRepeatOrder}
                className="button button-secondary button-normal w-full"
              >
                Повторить заказ
              </button>
            </div>
          </div>
        </div>
      </div>
      {!detailsExists && <div className="text-gray-48 text-sm mb-4">* Чек будет доступен после сборки заказа</div>}
      {detailsShown && <OrderDetails data={data} />}
    </div>
  );
};

export const fragment = /* urqlGraphQL */ `
  fragment MyHistoryOrder_data on Order {
    deliveryAt
    deliveredAt
    status
    shopId
    asap
    deliveryCost
    itemSum
    totalSum
    items {
      product {
        rowId
        imageUrl: mainImage(size: 285)
        retinaImageUrl: mainImage(size: 570)
        counterInit
        counterStep
        price
        status
      }      
    }
    ...OrderDetails_data
  }
`;
