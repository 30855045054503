import * as React from 'react';
import { navigate } from 'gatsby';
import { useGlobal } from './hooks';
import { Modal } from './Modal';
import { CartModal } from '../modals/Cart';
import { MyModal } from '../modals/My';
import { CheckoutModal } from '../modals/Checkout';
import { CheckoutFinishModal } from '../modals/CheckoutFinish';
import { ForgotPasswordModal } from '../modals/ForgotPassword';

const modals: { [key in string]: any } = {
  cart: CartModal,
  my: MyModal,
  checkout: CheckoutModal,
  checkoutFinish: CheckoutFinishModal,
  'forgot-password': ForgotPasswordModal,
};

export const ModalLogic = () => {
  const [modal, globalActions] = useGlobal((s) => s.modal);

  if (!modal) {
    return null;
  }

  const Content = modals[modal];

  if (!Content) {
    return null;
  }

  const onClose = () => {
    globalActions.setModal(null);
    window.history.replaceState(null, '', '?modal=null');
  };

  return (
    <Modal onClose={onClose}>
      <Content onClose={onClose} />
    </Modal>
  );
};

type ButtonProps = {
  readonly to: string;
};

export const ModalButton = ({ to, ...props }: ButtonProps & React.HTMLProps<HTMLButtonElement>) => {
  const [, globalActions] = useGlobal(() => null);

  const onClick = () => {
    globalActions.setModal(to);
    window.history.replaceState(null, '', `?modal=${to}`);
  };
  /* eslint-disable react/jsx-props-no-spreading */
  // @ts-ignore
  return <button type="button" onClick={onClick} {...props} />;
};

export const ModalLink = ({ to, ...props }: ButtonProps & React.HTMLProps<HTMLButtonElement>) => {
  const [, globalActions] = useGlobal(() => null);

  const onClick = () => {
    globalActions.setModal(null);
    navigate(to, { replace: true });
  };
  /* eslint-disable react/jsx-props-no-spreading */
  // @ts-ignore
  return <button type="button" onClick={onClick} {...props} />;
};
