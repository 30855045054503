import * as React from 'react';
import classnames from 'classnames';
import IconGray from 'components/icons/AlertCircleGray';
import IconOrange from 'components/icons/AlertCircleOrange';
import { ModalLink } from './ModalLogic';
import s from './Tooltip.module.css';

type Props = {
  readonly label: string;
  readonly to?: string;
  readonly className?: string;
};

export const Tooltip = ({ to, label, className }: Props) => {
  const Component = to ? ModalLink : 'div';

  return (
    // @ts-ignore
    <Component to={to} className={classnames(s.root, className)}>
      <IconGray size={20} className={classnames(s.icon, s.iconGray)} />
      <IconOrange size={20} className={classnames(s.icon, s.iconOrange)} />
      <div className={s.contentWrapper}>
        <div className={s.content}>
          <div className={s.contentInner}>{label}</div>
        </div>
      </div>
    </Component>
  );
};
