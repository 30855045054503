import * as React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import logoImg from 'images/logo.png';
import retinaLogoImg from 'images/logo@2x.png';
import orderingImg from 'images/ordering.png';
import retinaOrderingImg from 'images/ordering@2x.png';
import qrImg from 'images/qrWhiteBorder.png';
import retinaQrImg from 'images/qrWhiteBorder@2x.png';
import { AppLink } from './AppLink';
import { Icon } from './icons';
import s from './Footer.module.css';

const socialLinkCn =
  'flex items-center justify-center w-12 h-12 rounded-lg border-2 border-white border-opacity-20 transition-colors duration-300 hover:border-opacity-100 focus:border-opacity-100';

const linkCn =
  'text-mf-gray-1 text-sm font-bold leading-4 mr-3 transition-colors duration-300 hover:text-white focus-within:text-white';

export const Footer = () => (
  <footer className="bg-mf-black pt-10 lg:pb-10">
    <div className="mf-container flex lg:flex-col">
      <div className="flex flex-col items-start w-full">
        <div className="flex items-center mb-16 lg:mb-10">
          <Link to="/" className="mr-12">
            <img src={logoImg} srcSet={`${retinaLogoImg} 2x`} alt="" />
          </Link>
          <a
            href="https://www.instagram.com/moscow_fresh/"
            target="_blank"
            rel="noopener noreferrer"
            className={cn(socialLinkCn, 'mr-4')}
          >
            <Icon name="instagramWhite" />
          </a>
          <a
            href="https://www.facebook.com/moscowfresh"
            target="_blank"
            rel="noopener noreferrer"
            className={socialLinkCn}
          >
            <Icon name="facebookWhite" />
          </a>
        </div>
        <a href="tel:84951201841" className="text-white text-2xl font-bold leading-8 lg:mb-1">
          8 (495) 120-18-41
        </a>
        <a
          href="mailto:client@moscowfresh.ru"
          className="text-mf-gray-1 text-sm leading-4 underline transition-colors duration-300 hover:text-white hover:no-underline"
        >
          client@moscowfresh.ru
        </a>
        <div className="w-64 h-px bg-mf-gray-2 my-5" />
        <div className="text-mf-gray-3 text-sm leading-4 mb-2">© 2015-{new Date().getFullYear()} ООО «MoscowFresh»</div>
        <div className="flex items-center">
          <Link to="/privacy" className={linkCn}>
            Пользовательское соглашение
          </Link>
          <Link to="/privacy" className={linkCn}>
            Публичная оферта
          </Link>
        </div>
      </div>
      <div className="flex lg:mt-10">
        <img src={orderingImg} srcSet={`${retinaOrderingImg} 2x`} alt="" className="mr-12 lg:hidden" />
        <div>
          <div className="text-white leading-5 mb-3">Покупай свежие продукты удобнее.</div>
          <div className="text-white leading-5 mb-6">
            Отслеживай доставку, повторяй старые заказы, получай уведомления о спецпредложениях мгновенно.
          </div>
          <div className="flex">
            <div className={s.appLinks}>
              <AppLink type="appStore" className={s.appLink} />
              <AppLink type="googlePlay" className={s.appLink} />
            </div>
            <div className={s.qrWrapper}>
              <img src={qrImg} srcSet={`${retinaQrImg} 2x`} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
