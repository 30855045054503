import * as React from 'react';
import classnames from 'classnames';
import * as Sentry from '@sentry/browser';
import { useViewer } from 'components/Auth';
import EditGrayIcon from 'components/icons/EditGray';
import EditOrangeIcon from 'components/icons/EditOrange';
import PlusGrayIcon from 'components/icons/PlusGray';
import PlusOrangeIcon from 'components/icons/PlusOrange';
import { Price } from 'components/Price';
import { useGlobal } from 'components/hooks';
import { MyDeposit } from 'components/MyDeposit';
import { MyProfile } from 'components/MyProfile';
import { MyAddresses } from 'components/MyAddresses';
import { MyPassword } from 'components/MyPassword';
import { MyHistory } from 'components/MyHistory';
import { Tooltip } from 'components/Tooltip';
import { MyNewAddress } from 'components/MyNewAddress';
import { ActiveAddress } from 'components/ActiveAddress';
import { ModalTop } from 'components/ModalTop';
import { Loading } from 'components/Loading';
import { getQueryParams, formatPhone } from 'utils';
import { useMySignOutMutation } from '__generated__/urql';
import s from './My.module.css';

type Props = {
  readonly onClose: () => void;
};

export const MyModal = ({ onClose }: Props) => {
  const qp = getQueryParams();
  const tabQP = typeof qp.tab === 'string' ? qp.tab : '';

  const [, globalActions] = useGlobal(() => null);
  const [viewer, viewerView] = useViewer();
  const [signOutMutationState, signOutMutation] = useMySignOutMutation();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [tab, setTab] = React.useState(tabQP);

  if (viewerView) {
    return viewerView;
  }

  if (viewer && viewer.user) {
    const signOut = async () => {
      try {
        const res = await signOutMutation();
        if (res.data && res.data.signOut.token) {
          onClose();
          globalActions.updateToken(res.data.signOut.token);
        } else {
          throw new Error();
        }
      } catch (e) {
        setErrorMessage('Произошла внутренняя ошибка');
        Sentry.captureException(e);
      }
    };

    const toTab = (t: string, queryParams?: string) => {
      setTab(t);
      window.history.replaceState(null, '', `?modal=my&tab=${t}${queryParams ?? ''}`);
    };

    const tabs: {
      [key in string]: {
        title?: string;
        content: JSX.Element;
      };
    } = {
      history: {
        title: 'История заказов',
        content: <MyHistory />,
      },
      deposit: {
        title: 'Депозит',
        content: <MyDeposit data={viewer.user} />,
      },
      addresses: {
        title: 'Адреса',
        content: <MyAddresses data={viewer.user} toTab={toTab} />,
      },
      profile: {
        title: 'Профиль',
        content: <MyProfile data={viewer.user} />,
      },
      password: {
        title: 'Пароль',
        content: <MyPassword />,
      },
      newAddress: {
        content: <MyNewAddress toTab={toTab} />,
      },
    };

    const tabsKeys = Object.keys(tabs);
    const currentTab = tabsKeys.find((t) => t === tab) || 'history';

    return (
      <div className="modal-big flex flex-col pb-8" style={{ minHeight: 660 }}>
        <ModalTop title="Личный кабинет" type="big">
          <button
            type="button"
            onClick={signOut}
            className="h-14 px-8 text-gray-64 font-bold leading-5 transition-colors duration-300 hover:text-white"
          >
            Выйти из аккаунта
          </button>
        </ModalTop>
        <div className="flex flex-col flex-grow modal-content">
          <div className="flex px-8 my-6">
            <div className="mr-12">
              <div className="text-black text-xl font-bold leading-6 mb-1">
                {viewer.user.firstName} {viewer.user.lastName}
              </div>
              <button
                type="button"
                onClick={() => toTab('profile')}
                className="group relative flex items-center text-gray-48 leading-6 transition-colors duration-300 hover:text-black"
              >
                {viewer.user.mainPhone ? formatPhone(viewer.user.mainPhone) : 'Добавить телефон'}
                <EditGrayIcon size={16} className="ml-2 transition-opacity duration-300 group-hover:opacity-0" />
                <EditOrangeIcon
                  size={16}
                  className="absolute right-0 opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                />
              </button>
            </div>
            <div className="mr-12">
              <div className="flex items-center mb-1">
                <span className="text-gray-64 font-bold leading-6 mr-2">Депозит:</span>
                <Tooltip label="Нажмите, чтобы узнать подробнее о депозите" to="/deposit" />
              </div>
              <button
                type="button"
                onClick={() => toTab('deposit')}
                className="group relative flex items-center text-gray-48 leading-6 transition-colors duration-300 hover:text-black"
              >
                <Price value={viewer.user.depositBalance} />
                <PlusGrayIcon size={16} className="ml-2 transition-opacity duration-300 group-hover:opacity-0" />
                <PlusOrangeIcon
                  size={16}
                  className="absolute right-0 opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                />
              </button>
            </div>
            <div className="flex flex-col items-start mr-12">
              <div className="flex items-center mb-1">
                <span className="text-gray-64 font-bold leading-6 mr-2">Адрес доставки:</span>
                <Tooltip label="Нажмите, чтобы узнать подробнее о доставке" to="/delivery" />
              </div>
              <ActiveAddress data={viewer.user} type="My" toTab={toTab} className="leading-5" />
            </div>
          </div>
          <div className="flex items-center border-t border-b border-gray-90 px-8 mb-8">
            {tabsKeys.map((t) => {
              const { title } = tabs[t];

              if (title) {
                return (
                  <button
                    key={t}
                    type="button"
                    onClick={() => toTab(t)}
                    className={classnames(s.tabsButton, currentTab === t && s.active)}
                  >
                    {title}
                  </button>
                );
              }

              return null;
            })}
          </div>
          {errorMessage && <div className="text-red text-2xl font-bold leading-none px-8 mb-6">{errorMessage}</div>}
          <div className="flex flex-col flex-grow px-8 pb-8">{tabs[currentTab].content}</div>
          {signOutMutationState.fetching && <Loading inModal />}
        </div>
      </div>
    );
  }

  return null;
};

export const fragment = /* urqlGraphQL */ `
  fragment My_data on User {
    firstName
    lastName
    mainPhone
    depositBalance
    email
    ...MyDeposit_data
    ...MyProfile_data
    ...MyAddresses_data
    ...ActiveAddress_data
  }
`;

export const mutation = /* urqlGraphQL */ `
  mutation MySignOut {
    signOut {
      token
    }
  }
`;
