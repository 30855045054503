import * as React from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';
import { selectors } from 'store';
import { useGlobal } from './hooks';
import { Price } from './Price';
import CartIcon from './icons/CartWhite';
import s from './CartCounter.module.css';

type Props = {
  readonly data: GatsbyTypes.CartCounter_DataFragment;
  readonly type: 'Banner' | 'product' | 'ProductCard' | 'ProductListItem';
  readonly className?: string;
};

export const CartCounter = ({ data, type, className }: Props) => {
  const [quantity, globalActions] = useGlobal((state) => selectors.cart.getQuantityById(state, data.rowId));

  const isBanner = type === 'Banner';
  const isProduct = type === 'product';
  const isProductCard = type === 'ProductCard';
  const isProductListItem = type === 'ProductListItem';

  const byRequestCn = classnames({
    'font-bold': true,
    'self-center': isBanner,
    'text-red': !isBanner,
    'mt-auto': isProductListItem,
    // @ts-ignore
    [className]: className,
  });

  if (data.status === 'BY_REQUEST') {
    return <div className={byRequestCn}>Нет в наличии</div>;
  }

  const q = Math.max(quantity, 1);
  const totalSum = q * data.price;
  const oldTotalSum = q * (data.oldPrice || 0);

  const increase = () => globalActions.cart.increaseQuantity(data);

  const decrease = () => globalActions.cart.decreaseQuantity(data);

  let quantityLabel = `${q} шт`;
  let { unit } = data;

  if ((unit === 'гр' || unit === 'г') && data.weight) {
    const v = Math.round(quantity * data.weight * 100) / 100;
    if (v < 1) {
      quantityLabel = `${Math.round(v * 1000)} г`;
    } else {
      unit = 'кг';
    }
  }

  if (unit === 'кг' && data.weight) {
    const v = Math.round(quantity * data.weight * 100) / 100;
    quantityLabel = `${v} кг`;
  }

  const rootCn = classnames({
    [s.root]: true,
    // @ts-ignore
    [className]: className,
    [s.rootBanner]: isBanner,
    [s.rootProductListItem]: isProductListItem,
  });

  const pricesCn = classnames({
    [s.prices]: true,
    [s.pricesBanner]: isBanner,
    [s.pricesProductCard]: isProductCard && oldTotalSum > 0,
  });

  const priceCn = classnames({
    [s.price]: true,
    [s.priceBanner]: isBanner,
    [s.priceProduct]: isProduct,
    [s.priceProductCard]: isProductCard,
    [s.priceProductListItem]: isProductListItem,
    [s.priceBlack]: (isProductCard || isProductListItem) && !oldTotalSum,
  });

  const oldPriceCn = classnames({
    [s.oldPrice]: true,
    [s.oldPriceBanner]: isBanner,
    [s.oldPriceProduct]: isProduct,
    [s.oldPriceProductCard]: isProductCard,
    [s.oldPriceProductListItem]: isProductListItem,
  });

  const controlsCn = classnames({
    [s.controls]: true,
    [s.controlsBanner]: isBanner,
    [s.controlsProduct]: isProduct,
    [s.controlsProductCard]: isProductCard,
    [s.controlsProductListItem]: isProductListItem,
  });

  const controlCn = classnames({
    [`button ${s.control}`]: true,
    [`button-primary ${s.controlBanner}`]: isBanner,
    [`button-primary ${s.controlProduct}`]: isProduct,
    [`button-secondary ${s.controlProductCard}`]: isProductCard,
    [`button-secondary ${s.controlProductListItem}`]: isProductListItem,
  });

  const quantityLabelCn = classnames({
    [s.quantityLabel]: true,
    [s.quantityLabelBanner]: isBanner || isProduct,
    [s.quantityLabelProductCard]: isProductCard,
  });

  const addButtonCn = classnames({
    button: true,
    [`button-primary ${s.addButtonBanner}`]: isBanner,
    [`button-primary ${s.addButtonProduct}`]: isProduct,
    [`button-secondary ${s.addButtonProductCard}`]: isProductCard,
  });

  let controls = (
    <div className={controlsCn}>
      <button type="button" onClick={decrease} className={controlCn}>
        –
      </button>
      <div className={quantityLabelCn}>{quantityLabel}</div>
      <button
        type="button"
        onClick={increase}
        className={controlCn}
        disabled={data.maxQuantityPerOrder ? q >= data.maxQuantityPerOrder : false}
      >
        +
      </button>
    </div>
  );

  if (quantity === 0) {
    controls = (
      <button type="button" onClick={increase} className={addButtonCn}>
        {isBanner ? (
          <>
            <CartIcon className="mr-2" />
            Добавить в корзину
          </>
        ) : (
          <>Добавить</>
        )}
      </button>
    );
  }

  return (
    <div className={rootCn}>
      <div className={pricesCn}>
        <Price value={totalSum} className={priceCn} />
        {oldTotalSum > 0 && <Price value={oldTotalSum} className={oldPriceCn} />}
      </div>
      {controls}
    </div>
  );
};

export const query = graphql`
  fragment CartCounter_data on Api_Product {
    rowId
    price
    oldPrice
    unit
    weight
    status
    maxQuantityPerOrder
    counterInit
    counterStep
  }
`;
